body {
  padding: 0;
  margin: 0;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset;
}
input:-webkit-autofill,
input:-webkit-autofill::first-line,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, color 5000s;

  font-size: 16px;
}
