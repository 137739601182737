.mux-date-range-picker {
  box-sizing: border-box;
  min-width: 270px;
  height: 40px;
  border: 1px solid rgb(192, 192, 192);
  outline: none;
  border-radius: 4px;
  width: 100%;
  padding: 8.5px 14px;
  font-size: 16px;
  box-shadow: 0px 0px 1px 0px rgb(192, 192, 192);
}

.mux-date-range-picker input:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.mux-date-range-picker input:focus {
  border: 1px solid #5a2af5;
  box-shadow: 0px 0px 0px 1px #5a2af5;
}
